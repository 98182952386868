<template>
  <p class="gavc-text-small gavc-text-martinique mt-2">
    <small><i class="gavc-icon gavc-icon-cross" /> {{ message }} </small>
  </p>
</template>

<script>
export default {
  name: 'inputErrorValidationMessage',
  props: {
    message: {
      type: String,
      default: ""
    }
  }
}
</script>
